<template>
    <div class="maxbox">
        <div>
            <div class="top">
                <p>我的资产</p>
            </div>
            <div class="main">
                <button @click="goback" class="return"><img src="@/assets/images/left-arrow.png" alt="返回"></button>
                <div class="main-content">
                    <h2>奖金明细</h2><br>
                    <p id="directReferralRewards">我的推荐奖：{{ directReferralRewards }} USDT</p>
                    <p id="referralRewards">我的见点奖：{{ referralRewards }} USDT</p>
                    <p id="upgradeRewards">我的升级奖：{{ upgradeRewards }} USDT</p><br>
                    <p id="totalRewards">奖金总额：{{ totalRewards }} USDT</p>
                    <!-- <button class="withdraw-button" @click="withdrawBonuses">提取奖金</button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, contractABI } from '@/js/contract.js';

export default {
    name: 'income',
    data() {
        return {
            directReferralRewards: '加载中...',
            referralRewards: '加载中...',
            upgradeRewards: '加载中...',
            totalRewards: '加载中...',
        }
    },
    methods: {
        goback() {
            this.$router.go(-1);
        },
        async connectWallet() {
            if (window.ethereum) {
                try {
                    await window.ethereum.request({ method: 'eth_requestAccounts' });
                    return new ethers.providers.Web3Provider(window.ethereum);
                } catch (error) {
                    console.error("用户拒绝了账户访问请求", error);
                }
            } else {
                console.error("未检测到Ethereum提供商。请安装MetaMask。");
            }
        },
        async getUserBonuses() {
            try {
                const provider = await this.connectWallet();
                if (!provider) return;

                const signer = provider.getSigner();
                const contract = new ethers.Contract(contractAddress, contractABI, signer);
                const userAddress = await signer.getAddress();

                if (!userAddress) {
                    alert("无法检测到用户地址，请先连接到MetaMask。");
                    return;
                }

                // 获取奖金数据
                const [totalRewards, referralRewards, upgradeRewards, directReferralRewards] = await Promise.all([
                    contract.viewTotalRewards(),
                    contract.referralRewards(userAddress),
                    contract.upgradeRewards(userAddress),
                    contract.getDirectReferralRewards() // 获取直接推荐奖金
                ]);

                const formatUSDT = (value) => ethers.utils.formatUnits(value, 6);

                // 更新显示
                this.totalRewards = formatUSDT(totalRewards);
                this.referralRewards = formatUSDT(referralRewards);
                this.upgradeRewards = formatUSDT(upgradeRewards);
                this.directReferralRewards = formatUSDT(directReferralRewards); // 设置直接推荐奖

            } catch (error) {
                console.error("查询奖金失败", error);
                alert("查询失败，请确保已连接到MetaMask并检查网络。");
            }
        },
        async withdrawBonuses() {
            try {
                const provider = await this.connectWallet();
                if (!provider) return;

                const signer = provider.getSigner();
                const contract = new ethers.Contract(contractAddress, contractABI, signer);
                const withdrawTx = await contract.withdraw();
                await withdrawTx.wait();
                alert("提取成功！");
                this.getUserBonuses();  // 更新显示信息
            } catch (error) {
                console.error("提取失败", error);
                alert("提取失败，错误详情见控制台。");
            }
        }
    },
    mounted() {
        this.getUserBonuses();
    }
}
</script>

<style scoped>
@import "@/assets/css/style.css";
@import "@/assets/css/detill.css";

.main {
    padding-top: 50px;
}
</style>